<template>
    <div>
        <ValidationObserver ref="formModalValidate">
                    <b-row>
                        <b-form-group class="col-12" :label="$t('name')">
                            <ValidationProvider
                                name="name"
                                rules="required"
                                v-slot="{ valid, errors }"
                            >
                                <b-input-group class="mb-3" >
                                    <b-form-input
                                        v-model="form.name"

                                    >
                                    </b-form-input>
                                </b-input-group>
                                <b-form-invalid-feedback
                                    v-if="errors[0]"
                                    v-html="errors[0]"
                                    class="mb-2"
                                ></b-form-invalid-feedback>
                            </ValidationProvider>
                        </b-form-group>
                        <div class="col-12">
                            <ValidationProvider
                                name="code"
                                rules="required"
                                v-slot="{ valid, errors }"
                            >
                                <b-form-group :label="$t('code')">
                                    <b-form-input

                                        v-model="form.code"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                    ></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <ValidationProvider
                                name="rank"

                                v-slot="{ valid, errors }"
                            >
                                <b-form-group :label="$t('rank')">
                                    <b-form-input

                                        v-model="form.rank"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                    ></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <ValidationProvider
                                name="data"

                                v-slot="{ valid, errors }"
                            >
                                <b-form-group :label="$t('data')">
                                    <b-form-input
                                        v-model="form.data"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                    ></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                    </b-row>
                </ValidationObserver>

                <div class="col-12 mt-3 d-flex">
                    <b-button
                        @click="createForm"
                        :disabled="formLoading"
                        type="button"
                        variant="primary"
                        class="btn-lg mr-2"
                    >
                        {{ $t("save") | toUpperCase }}
                    </b-button>
                </div>
    </div>
</template>

<script>
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import ParameterItemService from "@/services/ParameterItemService";

    export default {
        components: {
            ValidationProvider, ValidationObserver,
        },
        props:{
            parameter_id: null
        },
        data() {
            return {
                formLoading: false,
                form: {
                    rank:null,
                    data:null,
                    code: null,
                    name: null,
                }
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    this.form.parameter_id=this.parameter_id;
                    ParameterItemService.storeItems(this.form)
                                    .then((response) => {
                                        this.$toast.success(this.$t("api." + response.data.message));
                                        this.$emit("createFormSuccess")
                                    })
                                    .catch((error) => {
                                        this.showErrors(error, this.$refs.formModalValidate)
                                    });
                }
            }
        }
    }
</script>
